import React from 'react';
import { base_url_src } from '../../app/utility/const';
import { iNoImage } from '../../app/utility/imageImports';
import CommonModal from '../modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageViewerModal = ({ showModal, setShowModal, src = null, is_signature = false, url }) => {

  const fileExtension = url?.split('.')?.pop()?.toLowerCase();

  // Define the known image file extensions
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

  // Define the known PDF file extensions
  const pdfExtensions = ["pdf"];

  const { t } = useTranslation();


  return (
    <>
      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={t("Attachment Preview")}
        modalSpace={true}
        heightMax='max-h-[600px]'
        widthClass='w-auto'
        mainContent={
          <div className='h-[440px] max-h-[440px] max-w-[600px]'>

            <div className={`mt-s20 pb-s10 w-full flex flex-row justify-center ${is_signature ? 'h-[320px]' : 'h-full'} `}>

              {imageExtensions?.includes(fileExtension) &&
                <img
                  src={src ? (base_url_src + src) : iNoImage}
                  alt="img"
                  className="object-cover h-full"
                />
              }
            </div>

            {
              pdfExtensions.includes(fileExtension) && <div className='w-full truncate border-2 border-dashed border-cBrand p-s10 rounded-br5'>
                <a
                  href={base_url_src + url}
                  download="Attachment.pdf"
                  target="_blank"
                  rel="noreferrer"
                  alt=""
                  className="text-cBrand"
                > Attachment.pdf</a>
              </div>
            }
          </div>
        }
      />
    </>
  );
};

export default ImageViewerModal;