import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import {
  base_url_src,
  user_role as role,
} from "../../../../../../../app/utility/const";
import {
  iBorderedImageUploadIcon,
  iCloseIcon,
  iImageUploadIcon,
  iPlus,
} from "../../../../../../../app/utility/imageImports";
import { formatDate, formatDateToApiDate, getImageExtension, getImageExtensionByBase64, isWhitespaceOnly } from "../../../../../../../app/utility/utilityFunctions";
import ImageBtn from "../../../../../../../components/image/ImageBtn";
import ImageUpload from "../../../../../../../components/imageUpload/ImageUpload";
import ImageUploadViewModal2 from "../../../../../../../components/imageUpload/ImageUploadViewModal2";
import AddressAutoComplete from "../../../../../../../components/input/AddressAutoComplete";
import CommonDatePicker from "../../../../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../../../../components/input/CommonInput";
import CommonTimePicker from "../../../../../../../components/input/CommonTimePicker";
import CommonViewComponent from "../../../../../../../components/viewer/CommonViewComponent";

const DeliveryDetails = ({ index, item, hasVisited = false }) => {
  const { user_role } = useGeneralStore();
  const {
    cr_form,
    tampDeliveriesIndex,
    setTampDeliveries,
    tampDeliveries,
    updateTampDeliveries,
    updateTampDeliveriesProductField,
    isInvalidProduct,
    setIsInvalidProduct,

  } = useCreateRequestStore();
  const [doSearch, setDoSearch] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const { t } = useTranslation();
  const [show_modal, setShowModal] = useState(false);
  const handleBlurEvents = (type, event) => {
    switch (type) {
      case "address":
        if (!item?.lat) {
          if (errorFields.indexOf("address") === -1) {
            setErrorFields([...errorFields, "address"]);
          }
        } else {
          let newFields = errorFields.filter((field) => field !== "address");
          setErrorFields(newFields);
        }
        return;
      case "date":
        if (!item?.date) {
          if (errorFields.indexOf("date") === -1) {
            setErrorFields([...errorFields, "date"]);
          }
        } else {
          let newFields = errorFields.filter((field) => field !== "date");
          setErrorFields(newFields);
        }
        return;
      case "start_time":
        if (!item?.start_time) {
          if (errorFields.indexOf("start_time") === -1) {
            setErrorFields([...errorFields, "start_time"]);
          }
        } else {
          let newFields = errorFields.filter((field) => field !== "start_time");
          setErrorFields(newFields);
        }
        return;
      case "end_time":
        if (!item?.end_time) {
          if (errorFields.indexOf("end_time") === -1) {
            setErrorFields([...errorFields, "end_time"]);
          }
        } else {
          let newFields = errorFields.filter((field) => field !== "end_time");
          setErrorFields(newFields);
        }
        return;
      case "time": {
        if (!item.start_time && !item.end_time) {
          if (
            errorFields.indexOf("start_time") === -1 &&
            errorFields.indexOf("end_time") === -1
          ) {
            setErrorFields([...errorFields, "start_time", "end_time"]);
          } else if (
            errorFields.indexOf("start_time") === -1 &&
            errorFields.indexOf("end_time") !== -1
          ) {
            setErrorFields([...errorFields, "start_time"]);
          } else if (
            errorFields.indexOf("start_time") !== -1 &&
            errorFields.indexOf("end_time") === -1
          ) {
            setErrorFields([...errorFields, "end_time"]);
          }
        } else {
          let newFields = errorFields.filter(
            (field) => field !== "end_time" && field !== "start_time"
          );
          setErrorFields(newFields);
        }
      }
      case "product":
        if (!event?.target?.value) {
          if (errorFields.indexOf("product") === -1) {
            setErrorFields([...errorFields, "product"]);
          }
        } else if (
          item.products.filter((item) => !!item.text).length !==
          item.products.length
        ) {
          if (errorFields.indexOf("product") === -1) {
            setErrorFields([...errorFields, "product"]);
          }
        } else if (
          item.products.filter((item) => !!item.text).length ===
          item.products.length
        ) {
          if (errorFields.indexOf("product") !== -1) {
            let newFields = errorFields.filter((field) => field !== "product");
            setErrorFields(newFields);
          }
        }
        return;
      default:
        console.log("default case.");
        break;

    }
  };

  const checkForMissingData = () => {
    let tempArray = [];
    if (!item.address) {
      tempArray.push("address");
    }
    if (!item.formatted_date) {
      tempArray.push("date");
    }
    if (!item.start_time) {
      tempArray.push("start_time");
    }
    if (!item.end_time) {
      tempArray.push("end_time");
    }
    if (
      item.products.filter((item) => !!item.text).length !==
      item.products.length
    ) {
      tempArray.push("product");
    }
    setErrorFields(tempArray);
  };

  const getAttachmentTitle = (item) => {
    let extension = ""
    if(item?.attachment_url) {
      extension = getImageExtension(item?.attachment_url);
    }
    else if(item?.attachment) {
      extension = getImageExtensionByBase64(item?.attachment);
    }
    if(extension) {
      return t("Attachment") + "." + extension;
    }
    else return t("Attachment");
  }

  useEffect(() => {
    if (hasVisited) {
      checkForMissingData();
    }
  }, [hasVisited]);


  return (
    <>
      {tampDeliveriesIndex === index ? (
        <>
          <div className="grid grid-cols-2 gap-x-6">
            <AddressAutoComplete
              className="w-full"
              required={true}
              label={t("Delivery Address")}
              name={"address"}
              address={tampDeliveries?.address}
              latName={"lat"}
              lngName={"lng"}
              onBlurEvent={() => handleBlurEvents("address")}
              changeValue={(name, value) => {
                if (name === "address") {
                  updateTampDeliveries("address", value?.toString());
                } else if (name === "lat") {
                  updateTampDeliveries("lat", value?.toString());
                } else if (name === "lng") {
                  updateTampDeliveries("lng", value?.toString());
                }
              }}
              isInvalid={(!item?.address && !item?.lat && !item?.lng) || !tampDeliveries?.address}
              doSearch={doSearch}
              setDoSearch={setDoSearch}
              onFavAddressModalItemClick={(address) => {
                setTampDeliveries({
                  ...tampDeliveries,
                  address: address?.address,
                  lat: address?.lat?.toString(),
                  lng: address?.lng?.toString(),
                  floor_number:
                    address.floor_number === null ? "" : address.floor_number,
                });
                handleBlurEvents("address");
              }}
            />

            <CommonInput
              max_input={55}
              type="text"
              labelText={t("Floor Number")}
              name={"floor_number"}
              value={tampDeliveries?.floor_number}
              onChange={(e) => {
                setTampDeliveries({
                  ...tampDeliveries,
                  floor_number: e.target.value,
                });
              }}
            />
          </div>

          {/* Delivery date and time */}
          {useGeneralStore.getState().user_role === role.company && (
            <div>
              <div className="grid grid-cols-4 mt-5 gap-x-6">
                <div className="w-full">
                  <CommonDatePicker
                    startDate={cr_form?.pickup?.date ? cr_form?.pickup?.date : new Date()}
                    required={false}
                    allowPastDate={true}
                    shouldUpdateValue={true}
                    label={t("Date")}
                    value={tampDeliveries?.date}
                    onChange={(date) => {
                      setTampDeliveries({ ...tampDeliveries, date: formatDateToApiDate(date) });
                    }}
                    onCloseEvent={() => handleBlurEvents("date")}
                  // isInvalid={errorFields?.includes("date")}
                  />
                </div>

                <CommonTimePicker
                  showExtendedTimeUi={false}
                  required={tampDeliveries?.date ? true : false}
                  label={t("Time")}
                  marginClass="mt-s12"


                  isCurrentTimeValidation={new Date(tampDeliveries?.date) === new Date()}
                  // isCustomMinTimeValidation={cr_form?.pickup?.date === tampDeliveries?.date}
                  // customMinTimeValue={cr_form?.pickup?.start_time ?? ""}

                  init_time={tampDeliveries?.start_time}
                  end_time={tampDeliveries?.end_time}
                  heightClass=""
                  name="start_time"
                  onChange={(start_time, end_time) => {
                    setTampDeliveries({
                      ...tampDeliveries,
                      start_time: start_time,
                      end_time: end_time,
                    });
                    handleBlurEvents("time");
                  }}
                />

                <CommonTimePicker
                  showExtendedTimeUi={false}
                  marginClass="mt-s12"
                  required={tampDeliveries?.date ? true : false}
                  label={t("Range")}
                  init_time={tampDeliveries?.end_time}
                  end_time={tampDeliveries?.end_time}
                  heightClass=""
                  name="start_time"
                  onChange={(start_time, end_time) => {
                    setTampDeliveries({
                      ...tampDeliveries,
                      end_time: start_time,
                    });
                    handleBlurEvents("end_time");
                  }}
                />

                <CommonInput
                  max_input={5}
                  type="number"
                  labelText={t("Duration (min)")}
                  parentClassName={"mt-s2"}
                  name={"duration"}
                  value={tampDeliveries?.duration === 0 ? "" : tampDeliveries?.duration}
                  onChange={(e) => {
                    setTampDeliveries({
                      ...tampDeliveries,
                      duration: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          )}

          {tampDeliveries?.products?.map((productItem, productIndex) => (
            <div
              className={`relative -mt-[${user_role === role.company ? "" : ""
                }]`}
              key={productIndex}
            >
              <CommonInput
                valuePaddingRight="28px"
                required={false}
                labelText={t("Product")}
                parentClassName={"!mt-5"}
                max_input={100}
                name={"product"}
                value={tampDeliveries?.products[productIndex]?.text === "Product" ? "" : tampDeliveries?.products[productIndex]?.text ?? ""}
                onChange={(e) => {
                  if (e.target.value.trim() === "")
                    updateTampDeliveriesProductField(
                      productIndex,
                      "text",
                      "Product"
                    );
                  else updateTampDeliveriesProductField(
                    productIndex,
                    "text",
                    e.target.value
                  );
                }}
                onBlurEvent={(e) => handleBlurEvents("product", e)}
              // isInvalid={isInvalidProduct && (productItem?.text === "" || productItem?.text === null)}
              />
              {tampDeliveries?.products?.length > 1 && (
                <div className="absolute right-0 top-3">
                  <ImageBtn
                    hoverImageSrc={iCloseIcon}
                    imageSrc={iCloseIcon}
                    hasHoverAction={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (productItem && productItem?.id) {
                        if (tampDeliveries?.removed_products_ids?.length > 0) {
                          setTampDeliveries({
                            ...tampDeliveries,
                            products: tampDeliveries?.products.filter((i, iIndex) => productIndex != iIndex),
                            removed_products_ids: [
                              ...tampDeliveries?.removed_products_ids,
                              productItem?.id,
                            ]
                          })
                        } else {
                          setTampDeliveries({
                            ...tampDeliveries,
                            products: tampDeliveries?.products.filter((i, iIndex) => productIndex != iIndex),
                            removed_products_ids: [
                              productItem?.id,
                            ]
                          })
                        }
                      } else {
                        setTampDeliveries({
                          ...tampDeliveries,
                          products: tampDeliveries?.products.filter((i, iIndex) => productIndex != iIndex)
                        })
                      }
                    }}
                    showTooltip={false}
                    hoverSize="w-7 h-7"
                    iconSize="max-w-[16px] min-w-[16px] min-h-[16px] max-h-[16px]"
                  />
                </div>
              )}
            </div>
          ))}

          <AddProduct
            onClick={() => {
              setTampDeliveries({
                ...tampDeliveries,
                products: [
                  ...tampDeliveries?.products,
                  { text: "" }
                ]
              });

              // const p = tampDeliveries?.products?.filter((i) => i?.text === "" || i?.text === null)
              // if (p?.length > 0) {
              //   setIsInvalidProduct(true)
              // } else {
              //   isInvalidProduct === true && setIsInvalidProduct(false)
              //   setTampDeliveries({
              //     ...tampDeliveries,
              //     products: [
              //       ...tampDeliveries?.products,
              //       { text: "" }
              //     ]
              //   });
              // }

            }} />

          <div className="mt-4 mb-5">
            <CommonInput
              labelText={t("Comment")}
              name={"comment"}
              value={isWhitespaceOnly(tampDeliveries?.comment) ? "" : tampDeliveries?.comment}
              onChange={(e) => {
                setTampDeliveries({
                  ...tampDeliveries,
                  comment: e.target.value,
                });
              }}
              parentClassName={"mt-5"}
              textarea={true}
              max={255}
              max_input={255}
            />
          </div>

          <ImageUpload
            setImage={(value) => {
              setTampDeliveries({
                ...tampDeliveries,
                attachment: value,
              });
            }}
            image_url={tampDeliveries?.attachment_url}
            src={tampDeliveries?.attachment}
            setSrc={(value) => { }}
            title="Add Attachment"
            imageUploadIcon={iBorderedImageUploadIcon}
            addButtonClassName="border border-dashed border-cGray300 p-s10 w-max"
            width="w-max"
            showInfoText={true}
            textStyle="text-fs14 font-fw400 text-cBrand leading-[25px]"
          />
        </>
      ) : (
        <div className="space-y-3">
          <CommonViewComponent
            labelText={t("Delivery Address")}
            value={item?.address ? item?.address : "No address"}
          />
          <CommonViewComponent
            labelText={t("Floor Number")}
            value={item?.floor_number ? item?.floor_number : "No floor number"}
          />
          {user_role === role.company && <CommonViewComponent
            labelText={t("Date")}
            value={item?.date ? formatDate(item?.date) : "No date"}
          />}
          {user_role === role.company && <CommonViewComponent
            labelText={t("Time")}
            value={item?.start_time || item?.end_time ? `${item?.start_time ?? "00:00"} - ${item?.end_time ?? "00:00"}` : "No time"}
          />}

          {user_role === role.company && <CommonViewComponent
            labelText={t("Duration (min)")}
            value={item?.duration ? item?.duration : "No duration"}
          />}
          {item?.products?.length > 0 &&
            item?.products?.map((product, productIndex) => (
              product?.text && <CommonViewComponent
                labelText={t("Product " + (parseInt(productIndex) + 1))}
                value={product?.text}
              />
            ))
          }
          <CommonViewComponent
            labelText={t("Comment")}
            value={item?.comment ? item?.comment : "No comment"}
          />
          {(item?.attachment || item?.attachment_url) && <div className="bg-cMainWhite w-[150px] h-[45px] flex rounded cp relative">
            <div onClick={() => setShowModal(true)} className="flex items-center justify-center space-x-2 text-center">
              <img
                src={iImageUploadIcon}
                alt="img"
                className="w-[15px] h-[15px]"
              />
              <span className="text-fs16 font-fw500 text-cMainBlack overflow-clip max-w-[130px] whitespace-nowrap">{getAttachmentTitle(item)}</span>
            </div>

            <ImageUploadViewModal2 fileName={t("Attachment")} show_modal={show_modal} setShowModal={setShowModal} apiImage={item?.id ? (base_url_src + item?.attachment_url) : null} url={item?.attachment} src={item?.attachment} />
          </div>}
        </div>
      )}
    </>
  );
};

export default DeliveryDetails;

const AddProduct = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="flex flex-row items-center justify-start mt-5 space-x-2 cursor-pointer w-max"
    >
      {/* <img src={iPlus} alt="" /> */}
      <div className="text-cBrand text-fs16 font-fw500">{t("Add Product")}</div>
    </div>
  );
};
