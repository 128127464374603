import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import useGeneralStore from '../../../../../../../app/stores/others/generalStore';
import { user_role as role } from '../../../../../../../app/utility/const';
import { removeHyphensFromString } from '../../../../../../../app/utility/utilityFunctions';
import BackLink from '../../../../../../../components/backLink/BackLink';

const Stepper = () => {

    const { t } = useTranslation();

    const params = useParams();

    const { request_id, type, shift_id } = params;

    const location = useLocation();

    const { user_role } = useGeneralStore();

    const isShiftDetails = window.location.pathname === `/requests/${type}/details/${request_id}/shift/${shift_id}`

    const step_array = () => {
        return [
            { label: t("Request Details"), linkTo: '', isActive: getCurrentPath(type), linkFn: '' },
        ]
    }

    const getCurrentPath = (type, action = null) => {
        if (type && !action) {
            return location?.pathname === `/requests/${type}/details/${request_id}`
        } else if (type && action) {
            return location?.pathname === `/requests/${type}/details/${request_id}/${action}`
        }
        else {
            return false;
        }
    }

    return (
        <div>
            {
                step_array().length > 0 ?
                    <div className="mb-5">
                        <BackLink
                            // cursorPointer={false}
                            linksArray={!isShiftDetails ?
                                [

                                    {
                                        label: type !== "awarded" ? (type === "history" && user_role === role.customer) ? "Completed" : removeHyphensFromString(type, type !== "on-going") : "Upcoming",
                                        isActive: false,
                                        cursorPointer: true,
                                        linkTo: location.state?.referrer === "global-request" ? "/global-request" : "/requests/" + type,
                                        linkFn: ''
                                    },
                                    {
                                        label: "Request Details",
                                        isActive: location.pathname === `/requests/${type}/details/${request_id}`,
                                        linkTo: location.state?.referrer === "global-request" ? `/global-request/details/${request_id}` : `/requests/${type}/details/${request_id}`,
                                        linkFn: ''
                                    },
                                    {
                                        label: location.pathname?.includes(`/requests/${type}/details/${request_id}/edit`) ? "Edit" : (location?.pathname?.includes(`/requests/in-bidding/details/${request_id}/shift/`) && location?.state?.isEditMode) ? "Edit" : "",
                                        linkTo: '',
                                        isActive: location.pathname === `/requests/${type}/details/${request_id}/edit`,
                                        linkFn: ''
                                    },
                                    // { label: location.pathname?.includes(`/requests/${type}/details/${request_id}/edit`) ? "Edit" : "", linkTo: '', isActive: getCurrentPath(type), linkFn: '' },

                                ] :
                                [{
                                    label: type !== "awarded" ? (type === "history" && user_role === role.customer) ? "Completed" : removeHyphensFromString(type, type !== "on-going") : "Upcoming",
                                    isActive: false,
                                    cursorPointer: true,
                                    linkTo: location.state?.referrer === "global-request" ? "/global-request" : "/requests/" + type,
                                    linkFn: ''
                                },
                                {
                                    label: "Request Details",
                                    isActive: location.pathname === `/requests/${type}/details/${request_id}`,
                                    linkTo: location.state?.referrer === "global-request" ? `/global-request/details/${request_id}` : `/requests/${type}/details/${request_id}`,
                                    linkFn: ''
                                },

                                {
                                    label: location.pathname?.includes(`/requests/${type}/details/${request_id}/edit`) ? "Edit" : (location?.pathname?.includes(`/requests/in-bidding/details/${request_id}/shift/`) && location?.state?.isEditMode) ? "Edit" : "",
                                    linkTo: '',
                                    isActive: location.pathname === `/requests/${type}/details/${request_id}/edit`,
                                    linkFn: ''
                                },
                                { label: "Shift Details", isActive: true, linkTo: '', linkFn: '' }
                                ]
                            }
                        />
                    </div> :
                    <div className='h-[21px]'>

                    </div>
            }
        </div>
    );
};

export default Stepper;