/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useRequestStore, { getRequestDetails } from '../../../../app/stores/others/requestStore'
import { k_page_titles, k_request_action, request_details_types, user_role as role } from '../../../../app/utility/const'
import { changePageTitle } from '../../../../app/utility/utilityFunctions'
import MainContents from './components/mainContents/MainContents'
import RequestDetailsTopBar from './components/requestDetailsTopBar/RequestDetailsTopBar'
import useLayoutStore from '../../../../app/stores/others/layoutStore'
import { useTranslation } from 'react-i18next'
import useGeneralStore from '../../../../app/stores/others/generalStore'
import BiddingReceived from '../../../customer/homePage/tables/inBiddings/components/BiddingReceived'
import Budget from '../../../customer/homePage/tables/inBiddings/components/Budget'
import ProposalInfo from './components/summary/components/ProposalInfo'
import AcknowledgeActionInfo from './components/summary/components/AcknowledgeActionInfo'
import AvailableShifts from './components/summary/components/AvailableShifts'
import PlaceBid from './components/summary/components/PlaceBid'
import RequestDetailsSummary from './components/summary/RequestDetailsSummary'
import AssignNotPlannedRequestShift from './components/AssignNotPlannedRequestShift'
import UpDateProposalInfo from './components/summary/components/UpDateProposalInfo'
import SavedCompany from './components/mainContents/components/SavedCompany'
import AddAcknowledgement from './components/summary/components/AddAcknowledgement'
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore'
import useShiftStore from '../../../../app/stores/company/shiftStore'
import RequestID from '../components/RequestID'

const RequestDetailsSection = () => {

    const params = useParams();
    const { type, request_id, action } = params;
    const { t } = useTranslation();
    const { setPathRecord } = useGeneralStore();
    const location = useLocation();
    const { selected_shift_index, setSelectedShiftIndex, updatePlaceBidForm, request_details, place_bid_form } = useRequestStore();
    const { setExpandedAccordion } = useCreateRequestStore();
    const { setShiftDetailsData } = useShiftStore()

    useEffect(() => {
        if (user_role === role.company && (type === request_details_types.in_bidding) && (request_details?.user?.role === role.customer)) {
            updatePlaceBidForm({ budget: request_details?.my_bid?.budget, description: request_details?.my_bid?.details, customer_name: request_details?.my_bid?.customer_name ?? request_details?.user?.name });
        } else if (type === request_details_types.invitation && user_role === role.company) {
            updatePlaceBidForm({ ...place_bid_form, description: "", budget: "", customer_name: request_details?.user?.name });
        }
    }, [request_details?.my_bid, action])


    useEffect(() => {
        fetchData();
        changePageTitle(t(k_page_titles.request_details));
        useLayoutStore.getState().setShowExpandedSidebarItem(true);

        return () => {
            setExpandedAccordion(null)
            setShiftDetailsData({})
        }
    }, [])

    const fetchData = async () => {
        if (location?.state?.is_fetch_shift_details === false) return;
        console.log("getRequestDetails called from: requestDetailsSection type:::id ", type, request_id);

        const success = await getRequestDetails(type, request_id);
        if (success?.success) window.scrollTo(0, 0);
    }

    useEffect(() => {
        setPathRecord(location.pathname);
        if (type === request_details_types.not_planned && (selected_shift_index !== null)) {
            setSelectedShiftIndex(null)
        }
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const { user_role } = useGeneralStore();

    const path = `/requests/${type}/details/${request_id}`;

    let Component;
    switch (location.pathname) {
        case `${path}/` + k_request_action.bidding:
            Component = BiddingReceived;
            break;
        case `${path}/` + k_request_action.budget:
            Component = Budget;
            break;
        case `${path}/` + k_request_action.proposal:
            Component = ProposalInfo;
            break;
        case `${path}/` + k_request_action.acknowledgement:
            Component = AcknowledgeActionInfo;
            break;
        case `${path}/` + k_request_action.AddAcknowledgement:
            Component = AddAcknowledgement;
            break;
        case `${path}/` + k_request_action.availableShifts:
            Component = AvailableShifts;
            break;
        case `${path}/` + k_request_action.placeBid:
            Component = PlaceBid;
            break;
        case `${path}/` + k_request_action.assignNotPlannedRequestShift:
            Component = AssignNotPlannedRequestShift;
            break;
        case `${path}/` + k_request_action.UpDateProposal:
            Component = UpDateProposalInfo;
            break;

        case `${path}/` + k_request_action.savedCompany:
            Component = SavedCompany;
            break;
        default:
            Component = MainContents;
    }


    return (
        <div>
            <RequestDetailsTopBar />



            <div className='flex space-x-10'>
                <div className='w-[calc(100%-420px)] min-w-[calc(100%-420px)]'>
                    <div className="pt-[6px] pb-1">
                        <RequestID request_id={request_details?.request_id} />
                    </div>
                    <Component />
                </div>
                <RequestDetailsSummary />
            </div>
        </div>
    )
}

export default RequestDetailsSection