/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import useShiftStore, {
  addNewShift,
  getAllShiftCarsAndDriversList,
} from "../../../../../app/stores/company/shiftStore";
import useCreateRequestStore from "../../../../../app/stores/others/createRequestStore";
import useRequestStore, {
  getRequestShiftPlannerList,
} from "../../../../../app/stores/others/requestStore";
import {
  Toastr,
  checkPastTime,
  getStringFromDateObject,
} from "../../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../../components/button/CommonButton";
import CommonDatePicker from "../../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../../components/input/CommonInput";
import CommonMultipleSelect from "../../../../../components/input/CommonMultipleSelect";
import CommonTimePicker from "../../../../../components/input/CommonTimePicker";
import CommonModal from "../../../../../components/modal/CommonModal";
import CommonSelect from "../../../../../components/select/CommonSelect";
import { useParams } from "react-router-dom";
import CommonViewComponent from "../../../../../components/viewer/CommonViewComponent";

const AddShift = () => {
  const { cr_form } = useCreateRequestStore();

  const {
    addShiftForm,
    setAddShiftForm,
    setAllShiftCarList,
    setAllShiftDriverList,
    resetAddShiftForm,
    allShiftCarList,
    allShiftDriverList,
    resetFilterFormCopy,
    showAddShiftModal,
    setShowAddShiftModal,
  } = useShiftStore();

  const { request_details } = useRequestStore();

  const [carDriverChooseMode, setCarDriverChooseMode] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isDisableOptions, setIsDisableOptions] = useState(true);
  const [time, setTime] = useState("");
  const [startingTime, setStartingTime] = useState("");
  const [clearData, setClearData] = useState(false);
  const { t } = useTranslation();
  const params = useParams();
  const { type, request_id } = params;

  let [endTime] = useDebounce(time, 1000);
  let [startTime] = useDebounce(startingTime, 1000);

  const resetCarDriverData = () => {
    setAllShiftCarList([]);
    setAllShiftDriverList([]);
    setAddShiftForm({
      ...addShiftForm,
      car_id: "",
      driver_user_id: "",
      co_drivers: [],
    });
    setIsDisableOptions(true);
  };

  const isValidFormData =
    addShiftForm?.start_date &&
    addShiftForm?.start_time &&
    addShiftForm?.end_time &&
    addShiftForm?.car_id &&
    addShiftForm?.driver_user_id &&
    addShiftForm?.comment
      ? true
      : false;

  // b    time validation
  const isTimeValid = (
    startTime,
    endTime,
    startDate = addShiftForm?.start_date,
    endDate = addShiftForm?.end_date
  ) => {
    const startDateTime = new Date(startDate + "T" + startTime);

    const endDateTime = new Date(endDate + "T" + endTime);

    return startDateTime < endDateTime;
  };

  const fetchCarAndDrivers = async () => {
    let validatedTime = await isTimeValid(
      addShiftForm?.start_time,
      addShiftForm?.end_time
    );
    let x;
    if (addShiftForm?.is_maintenance) {
      if (validatedTime) {
        //the loading can be removed from here by sending false as 5th parameter to load the data silently
        x = await getAllShiftCarsAndDriversList(
          addShiftForm?.start_date,
          addShiftForm?.end_date,
          addShiftForm?.start_time,
          addShiftForm?.end_time,
          true
        );
      } else {
        let start_date_next = addShiftForm?.start_date
          ? new Date(addShiftForm?.start_date)
          : null;
        start_date_next?.setDate(start_date_next?.getDate() + 1);

        x = await getAllShiftCarsAndDriversList(
          addShiftForm?.start_date,
          getStringFromDateObject(start_date_next ?? new Date()),
          addShiftForm?.start_time,
          addShiftForm?.end_time,
          true
        );
      }
      setIsDisableOptions(false);
      return;
    } else {
      if (validatedTime) {
        //the loading can be removed from here by sending false as 5th parameter to load the data silently
        x = await getAllShiftCarsAndDriversList(
          addShiftForm?.start_date,
          addShiftForm?.end_date,
          addShiftForm?.start_time,
          addShiftForm?.end_time,
          true
        );
      } else {
        let start_date_next = addShiftForm?.start_date
          ? new Date(addShiftForm?.start_date)
          : null;
        start_date_next?.setDate(start_date_next?.getDate() + 1);

        x = await getAllShiftCarsAndDriversList(
          addShiftForm?.start_date,
          getStringFromDateObject(start_date_next ?? new Date()),
          addShiftForm?.start_time,
          addShiftForm?.end_time,
          true
        );
      }
      setIsDisableOptions(false);
      // if (x?.cars?.length === 0) Toastr({ message: "No car found", type: "warning" });
      // if (x?.drivers?.length === 0) Toastr({ message: "No driver found", type: "warning" });
      return;
    }
  };

  const updateEndDate = async (addShiftForm, setAddShiftForm) => {
    const date = new Date(addShiftForm?.start_date);
    date.setDate(date.getDate() + 1);
    await setAddShiftForm({
      ...addShiftForm,
      end_date: date.toISOString().slice(0, 10),
    });
    return { ...addShiftForm, end_date: date.toISOString().slice(0, 10) };
  };

  const handleAddSuccess = async (
    addSuccess,
    resetAddShiftForm,
    resetFilterFormCopy,
    setShowAddShiftModal
  ) => {
    if (addSuccess) {
      resetAddShiftForm();
      await resetFilterFormCopy();
      setShowAddShiftModal(false);
      if (type && request_id) {
        await getRequestShiftPlannerList(
          request_details?.pickup_start_time,
          request_details?.pickup_end_time,
          request_details?.pickup_date
        );
        return;
      }
      cr_form?.pickup?.start_time &&
        cr_form?.pickup?.end_time &&
        cr_form?.pickup?.date &&
        (await getRequestShiftPlannerList(
          cr_form?.pickup?.start_time,
          cr_form?.pickup?.end_time,
          cr_form?.pickup?.date
        ));
      setAllShiftCarList([])
      setAllShiftDriverList([])
      setIsDisableOptions(true)
    }
  };

  const handleAddShift = async () => {
    if (canSubmit) {
      //time validations.
      if (checkPastTime(addShiftForm?.start_time, addShiftForm?.start_date)) {
        return Toastr({
          message: t("Start Time: Past Time not allowed."),
          type: "warning",
        });
      }

      let updatedForm = addShiftForm;
      if (
        addShiftForm?.start_time &&
        addShiftForm?.start_time > addShiftForm?.end_time
      ) {
        updatedForm = await updateEndDate(addShiftForm, setAddShiftForm);
      }

      if (
        addShiftForm?.start_date &&
        addShiftForm?.end_date &&
        addShiftForm?.start_time &&
        addShiftForm?.end_time
      ) {
        if (addShiftForm?.is_maintenance) {
          let addSuccess = await addNewShift(updatedForm);
          await handleAddSuccess(
            addSuccess,
            resetAddShiftForm,
            resetFilterFormCopy,
            setShowAddShiftModal
          );
        } else if (
          !addShiftForm?.is_maintenance &&
          addShiftForm?.car_license_plate_number &&
          addShiftForm?.driver_user_id &&
          addShiftForm?.comment
        ) {
          let addSuccess = await addNewShift(updatedForm, true);
          if (addSuccess) {
            await handleAddSuccess(
              addSuccess,
              resetAddShiftForm,
              resetFilterFormCopy,
              setShowAddShiftModal
            );
          }
        } else
          Toastr({
            message: t("Please fill up all required fields"),
            type: "warning",
          });
      }
    } else {
      Toastr({
        message: t("Please fill up all required fields"),
        type: "warning",
      });
    }
  };

  useEffect(() => {
    if (showAddShiftModal) {
      localStorage.setItem("add_shift_driver_user_id", 0);
      localStorage.setItem("add_shift_comment", "");
    }
    // fetchCarAndDrivers();
    return () => {
      resetAddShiftForm();
    };
  }, []);

  const autoCheckAndFetchDriverCar = async () => {
    if (isDisableOptions) fetchCarAndDrivers();
  };

  const handleModalClose = () => {
    setShowAddShiftModal(false);
    // setClearData(true);
    setStartingTime("");
    setTime("");
    setAllShiftCarList([]);
    setAllShiftDriverList([]);
    setIsDisableOptions(true)
    resetAddShiftForm();
    localStorage.setItem("add_shift_comment", "");
    localStorage.setItem("add_shift_driver_user_id", 0);
  };

  useEffect(() => {
    if (showAddShiftModal) {
      resetCarDriverData();

      if (!isTimeValid(addShiftForm?.start_time, endTime)) {
        let start_date_next = addShiftForm?.start_date
          ? new Date(addShiftForm?.start_date)
          : null;
        start_date_next?.setDate(start_date_next?.getDate() + 1);
        setAddShiftForm({
          ...addShiftForm,
          end_time: endTime,
          end_date: getStringFromDateObject(start_date_next ?? new Date()),
          car_id: null,
          car_license_plate_number: "",
          driver_user_id: "",
          co_drivers: [],
        });
      } else {
        if (
          isTimeValid(
            addShiftForm?.start_time,
            endTime,
            addShiftForm?.start_date,
            addShiftForm?.start_date
          )
        )
          setAddShiftForm({
            ...addShiftForm,
            end_time: endTime,
            end_date: addShiftForm?.start_date,
            car_id: null,
            car_license_plate_number: "",
            driver_user_id: "",
            co_drivers: [],
          });
        else
          setAddShiftForm({
            ...addShiftForm,
            end_time: endTime,
            car_id: null,
            car_license_plate_number: "",
            driver_user_id: "",
            co_drivers: [],
          });
      }
    }
  }, [endTime]);

  useEffect(() => {
    if (showAddShiftModal) {
      resetCarDriverData();
      if (!startTime && clearData) {
        setAddShiftForm({});
        return;
      }
      setAddShiftForm({
        ...addShiftForm,
        start_time: startTime,
        car_id: null,
        car_license_plate_number: "",
        driver_user_id: "",
        co_drivers: [],
      });
    }
  }, [startTime]);

  useEffect(() => {
    if (showAddShiftModal) {
      if (addShiftForm?.start_time && addShiftForm?.end_time)
        autoCheckAndFetchDriverCar();

      if (
        addShiftForm?.is_maintenance &&
        addShiftForm?.start_date &&
        addShiftForm?.end_date &&
        addShiftForm?.start_time &&
        addShiftForm?.end_time
      ) {
        setCarDriverChooseMode(true);
        if (addShiftForm?.car_id && addShiftForm?.car_license_plate_number)
          setCanSubmit(true);
        else setCanSubmit(false);
      } else if (
        !addShiftForm?.is_maintenance &&
        addShiftForm?.start_date &&
        addShiftForm?.start_time &&
        addShiftForm?.end_time
      ) {
        setCarDriverChooseMode(true);
        if (
          addShiftForm?.car_id &&
          addShiftForm?.car_license_plate_number &&
          addShiftForm?.driver_user_id &&
          addShiftForm?.comment
        )
          setCanSubmit(true);
        else setCanSubmit(false);
      } else setCarDriverChooseMode(false);
    }
  }, [addShiftForm, isDisableOptions]);

  useEffect(() => {
    if (showAddShiftModal) {
      cr_form?.pickup?.date &&
        setAddShiftForm({
          ...addShiftForm,
          start_date: getStringFromDateObject(cr_form?.pickup?.date),
          end_date: getStringFromDateObject(cr_form?.pickup?.date),
        });
    }
  }, [cr_form?.pickup?.date, showAddShiftModal]);

  return (
    <div>
      <CommonModal
        showModal={showAddShiftModal}
        setShowModal={handleModalClose}
        onCloseModal={handleModalClose}
        modalTitle={
          <div className="flex items-baseline">
            <div>{t("add shift")}</div>
            {addShiftForm?.start_date ? <div
              onClick={() => {
                setClearData(true);
                setStartingTime("");
                setTime("");
                setAddShiftForm({});
                localStorage.setItem("add_shift_comment", "");
                localStorage.setItem("add_shift_driver_user_id", 0);
              }}
              className="pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed"
            >
              {t("Clear")}
            </div> : ""}
          </div>
        }
        mainContent={
          <form className="w-full flex flex-col gap-5" onSubmit={(e) => e.preventDefault()}>
            {/* maintenance state selection */}

            {/*e       date pickers */}
            <div className="grid items-center grid-cols-2 pt-5 gap-7">
              <CommonDatePicker
                label={t("Start Date")}
                required={true}
                allowPastDate={false}
                value={addShiftForm?.start_date}
                startDate={new Date(cr_form?.pickup_date)}
                onChange={(date) => {
                  resetCarDriverData();
                  if (!addShiftForm?.is_maintenance)
                    setAddShiftForm({
                      ...addShiftForm,
                      start_date: getStringFromDateObject(date),
                      end_date: getStringFromDateObject(date),
                      car_id: null,
                      car_license_plate_number: "",
                      driver_user_id: "",
                      co_drivers: [],
                    });
                  else
                    setAddShiftForm({
                      ...addShiftForm,
                      start_date: getStringFromDateObject(date),
                      car_id: null,
                      car_license_plate_number: "",
                      driver_user_id: "",
                      co_drivers: [],
                    });
                }}
                show_asterisk={true}
              />
            </div>

            {/*p     time pickers */}
            <div className="mt-[6px] h-[38px] grid grid-cols-2 items-center gap-7">
              <CommonTimePicker
                heightClass="h-[38px]"
                disabled={addShiftForm?.start_date ? false : true}
                required={true}
                showExtendedTimeUi={false}
                label={t("start time")}
                init_time={addShiftForm?.start_time}
                onChange={(time) => {
                  setStartingTime(time);
                }}
                show_asterisk={true}
              />

              <CommonTimePicker
                heightClass="h-[38px]"
                required={true}
                disabled={addShiftForm?.start_date ? false : true}
                showExtendedTimeUi={false}
                label={t("End Time")}
                init_time={addShiftForm?.end_time}
                onChange={(time) => {
                  setTime(time);
                }}
                show_asterisk={true}
              />
            </div>

            {/*v           dropdowns          */}
            <div className="items-center">
                <CommonSelect
                  isReadOnly={isDisableOptions}
                  // disabled={carDriverChooseMode ? false : true}
                  disabled={isDisableOptions}
                  required={true}
                  label={t("Vehicle")}
                  placeholder={t("Select vehicle")}
                  withCancel={true}
                  onRemove={() => {
                    setAddShiftForm({ ...addShiftForm, car_id: "" });
                  }}
                  dataArray={allShiftCarList}
                  value={addShiftForm?.car_license_plate_number}
                  onChange={(e) => {
                    let car_id_found = allShiftCarList.find(
                      (car) => car.value === e.target.value
                    );
                    setAddShiftForm({
                      ...addShiftForm,
                      car_id: parseInt(car_id_found?.id),
                      car_license_plate_number: e.target.value,
                    });
                  }}
                  show_asterisk={true}
                />
            </div>

            <div className="items-center mt-s1">
                <CommonSelect
                  // disabled={!addShiftForm?.is_maintenance && carDriverChooseMode ? false : true}
                  disabled={isDisableOptions}
                  required={!addShiftForm?.is_maintenance}
                  label={t("Main Driver")}
                  placeholder={t("Select main driver")}
                  withCancel={true}
                  onRemove={() => {
                    setAddShiftForm({
                      ...addShiftForm,
                      driver_user_id: "",
                      co_drivers: [],
                    });
                  }}
                  dataArray={allShiftDriverList?.filter(
                    (item) => !addShiftForm?.co_drivers?.includes(item.value)
                  )}
                  show_image={true}
                  isRoundedFull={true}
                  value={
                    addShiftForm?.is_maintenance
                      ? null
                      : addShiftForm?.driver_user_id
                  }
                  onChange={(e) => {
                    localStorage.setItem(
                      "add_shift_driver_user_id",
                      e.target.value
                    );
                    if (!e.target.value) {
                      setAddShiftForm({
                        ...addShiftForm,
                        driver_user_id: e.target.value,
                        co_drivers: [],
                      });
                    } else {
                      setAddShiftForm({
                        ...addShiftForm,
                        driver_user_id: e.target.value,
                      });
                    }
                  }}
                  show_asterisk={true}
                />
            </div>

            <div className="items-center mt-[9px]">
                <CommonMultipleSelect
                  disabled={!addShiftForm?.driver_user_id}
                  dataArray={allShiftDriverList?.filter(
                    (item) => item?.value !== addShiftForm?.driver_user_id
                  )}
                  label={t("Co-Drivers")}
                  selectItem={allShiftDriverList?.filter((item) =>
                    addShiftForm?.co_drivers?.includes(item?.value)
                  )}
                  onSelectItem={(e) => {
                    if (!addShiftForm?.co_drivers?.includes(e?.value)) {
                      if (addShiftForm?.co_drivers?.length < 2) {
                        setAddShiftForm({
                          ...addShiftForm,
                          co_drivers: [...addShiftForm?.co_drivers, e.value],
                        });
                      } else {
                        Toastr({
                          message: t("Maximum 2 co-drivers allowed"),
                          type: "warning",
                        });
                      }
                    } else {
                      setAddShiftForm({
                        ...addShiftForm,
                        co_drivers: addShiftForm?.co_drivers?.filter(
                          (value) => value !== e.value
                        ),
                      });
                    }
                  }}
                  handleRemoveItem={(e) => {
                    setAddShiftForm({
                      ...addShiftForm,
                      co_drivers: addShiftForm?.co_drivers.filter(
                        (item) => item !== e.value
                      ),
                    });
                  }}
                  isRoundedFull={true}
                  noDataText={"No co-drivers available"}
                />
            </div>

            {/*g    comments  */}
            <div className="mt-[-15px]">
              <CommonInput
                disabled={addShiftForm?.is_maintenance}
                required={true}
                textarea={true}
                max_input={255}
                labelText={t("Shift Instruction")}
                value={
                  addShiftForm?.is_maintenance ? "" : addShiftForm?.comment
                }
                onChange={(e) => {
                  if (e.target.value !== " ") {
                    localStorage.setItem("add_shift_comment", e.target.value);
                    setAddShiftForm({
                      ...addShiftForm,
                      comment: e.target.value,
                    });
                  }
                }}
                show_asterisk={true}
              />
            </div>

            <div className="flex justify-end mt-s42">
              <CommonButton
                isDisabled={!isValidFormData}
                btnLabel={t("Add Shift")}
                icon={null}
                width="w-[130px]"
                type="button"
                onClick={handleAddShift}
              />
            </div>
          </form>
        }
      />
    </div>
  );
};

export default AddShift;
